<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="saveClient">

      <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('name') }">
        <input class="form-control"
               id="name"
               name="name"
               required
               type="text"
               placeholder="Имя клиента"
               v-model="form.name">
        <HasError :form="form" field="name" />
      </div>

      <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('phone') }">
        <label for="phone_two" class="font-small font-medium">
          Основной номер телефона
        </label>
        <vue-tel-input id="phone"
                       name="phone"
                       aria-placeholder="Номер телефона"
                       v-bind="phone_config"
                       v-model="form.phone">
        </vue-tel-input>
        <HasError :form="form" field="phone" />
      </div>

      <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('phone_two') }">
        <label for="phone_two" class="font-small font-medium">
          Второй номер телефона
        </label>
        <vue-tel-input id="phone_two"
                       name="phone_two"
                       aria-placeholder="Второй номер телефона"
                       v-bind="phone_config"
                       v-model="form.phone_two">
        </vue-tel-input>
        <HasError :form="form" field="phone_two" />
      </div>

      <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('comment') }">
        <textarea class="form-control"
                  maxlength="1000"
                  id="comment"
                  rows="3"
                  placeholder="Комментарий"
                  v-model="form.comment">
        </textarea>
        <HasError :form="form" field="comment" />
      </div>

      <br />

      <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

      <Button :disabled="!validateData || form.busy"
              :form="form"
              :loading="form.busy"
              class="btn btn-lg btn-success pull-right"
              style="min-width: 100px;">
        <i class="fa fa-save"></i>
      </Button>
    </form>
  </transition>
</template>

<script>
import Form from "vform";
import { VueTelInput } from "vue-tel-input";
import phone_config from "./../../assets/data/vue-tel-input-config.json";

Form.axios = API.apiClient;

export default {
  name: "create-quick-client-form",

  components: {
    VueTelInput,
    Button, HasError, AlertError, AlertErrors,
  },

  props: {},

  data: () => ({
    form: new Form({
      name: null,
      phone: null,
      phone_two: null,
      comment: null,
      is_quick_create: true,
    }),

    phone_config: phone_config,
  }),

  async created() {
    try {

    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {
    validateData: function() {
      return this.form.name && this.form.phone;
    },
  },

  methods: {
    async saveClient() {
      await this.form.post("/clients/add/quick").then(() => {
        showSuccess();
        this.EventBus.$emit("hide-quick-create-client-modal");
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style>

</style>
